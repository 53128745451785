var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { rounded: "xl", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 7 },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v("Mã lệnh cấp")]),
                      _c("span", [_vm._v(": " + _vm._s(_vm.item.releaseNo))]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Ngày cấp")]),
                      _c("span", [
                        _vm._v(
                          ": " +
                            _vm._s(
                              _vm.$filters.formatDate(_vm.item.releaseDate)
                            )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Shipper")]),
                      _c("span", [
                        _vm._v(
                          ": " + _vm._s(_vm.item.shipperName || "Chưa có")
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Depot")]),
                      _c("span", [
                        _vm._v(
                          ": " + _vm._s(_vm.item.depotTerminalName || "Chưa có")
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  class: !_vm.$vuetify.breakpoint.mobile
                    ? `d-flex text-truncate`
                    : `d-none`,
                  attrs: { cols: "2" },
                },
                [_c("v-chip", [_vm._v(" " + _vm._s(_vm.item.status) + " ")])],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  attrs: { cols: "3" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        dark: "",
                        tag: "a",
                        target: "_blank",
                        href: _vm.url,
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-pdf-box"),
                      ]),
                      _vm._v(" Xem file"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }