var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "display-1 mt-2 mb-5 text-uppercase" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("title-container-release-order-search")) + " "
      ),
    ]),
    _c(
      "div",
      {},
      [
        _c(
          "v-card",
          { staticClass: "py-1 px-2", attrs: { rounded: "lg" } },
          [
            _c(
              "v-card-text",
              [
                _c(
                  "v-form",
                  {
                    attrs: { onSubmit: "return false;" },
                    on: { submit: _vm.onSearch },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c("BaseTextField", {
                          attrs: {
                            clearable: "",
                            label: _vm.$t("label-cro-search"),
                            type: "text",
                            "prepend-inner-icon": "mdi-google-nearby",
                            solo: "",
                            "hide-details": "",
                            "item-text": "name",
                            "item-value": "code",
                            loading: _vm.isLoading,
                            readonly: _vm.isLoading,
                          },
                          on: { "click:clear": _vm.onClearSearch },
                          model: {
                            value: _vm.searchNo,
                            callback: function ($$v) {
                              _vm.searchNo = $$v
                            },
                            expression: "searchNo",
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-10",
                            class: {
                              "mt-2": _vm.$vuetify.breakpoint.mobile,
                            },
                            attrs: {
                              large: "",
                              width: _vm.$vuetify.breakpoint.mobile
                                ? "100%"
                                : undefined,
                              disabled: _vm.isLoading,
                              color: "primary",
                            },
                            on: { click: _vm.onSearch },
                          },
                          [
                            _c("v-icon", [_vm._v(" mdi-magnify ")]),
                            _vm._v(" " + _vm._s(_vm.$t("search")) + " "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-scroll-y-transition",
          [
            _vm.render && _vm.containerReleaseOrderList.length > 0
              ? _c("ContainerReleaseOrderSearchResult", {
                  staticClass: "mt-4 mb-16",
                  attrs: { loading: _vm.isLoading, "search-no": _vm.searchNo },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }