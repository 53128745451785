var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _vm._l(_vm.containerReleaseOrderList, function (item) {
            return _c("ContainerReleaseOrderSearchResultListItem", {
              key: item.id,
              attrs: { item: item },
            })
          })
        : _vm._e(),
      _vm.isLoading
        ? _vm._l(5, function (item, i) {
            return _c(
              "v-card",
              { key: i, staticClass: "mt-2 pa-3", attrs: { rounded: "xl" } },
              [
                _c(
                  "v-row",
                  { staticClass: "align-center", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-truncate", attrs: { cols: "8" } },
                      [
                        _c("v-skeleton-loader", {
                          attrs: {
                            boilerplate: false,
                            type: "list-item-three-line, list-item-three-line",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center align-center",
                        attrs: { cols: "2" },
                      },
                      [
                        _c("v-skeleton-loader", {
                          attrs: { boilerplate: false, type: "button" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center text-truncate",
                        attrs: { cols: "2" },
                      },
                      [
                        _c("v-skeleton-loader", {
                          attrs: {
                            boilerplate: false,
                            type: "list-item-two-line",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          })
        : _vm._e(),
      !_vm.isLoading && _vm.containerReleaseOrderList.length === 0
        ? _c("NoData")
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }