<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :placeholder="placeholder"
  >
    <template v-slot:label>
      <span
        v-if="required"
        class="red--text"
      >
        <strong>* </strong>
      </span>
      {{ label }}
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
