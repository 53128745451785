<template>
  <div>
    <template v-if="!isLoading">
      <ContainerReleaseOrderSearchResultListItem
        v-for="item in containerReleaseOrderList"
        :key="item.id"
        :item="item"
      />
    </template>

    <template v-if="isLoading">
      <v-card
        v-for="(item, i) in 5"
        :key="i"
        class="mt-2 pa-3"
        rounded="xl"
      >
        <v-row
          no-gutters
          class="align-center"
        >
          <v-col
            cols="8"
            class="text-truncate"
          >
            <v-skeleton-loader
              :boilerplate="false"
              type="list-item-three-line, list-item-three-line"
            />
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center align-center"
          >
            <v-skeleton-loader
              :boilerplate="false"
              type="button"
            />
          </v-col>
          <v-col
            cols="2"
            class="text-center text-truncate"
          >
            <v-skeleton-loader
              :boilerplate="false"
              type="list-item-two-line"
            />
          </v-col>
        </v-row>
      </v-card>
    </template>
    <NoData v-if="!isLoading && containerReleaseOrderList.length === 0" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContainerReleaseOrderSearchResultListItem from './ContainerReleaseOrderSearchResultListItem.vue';
export default {
  components: { ContainerReleaseOrderSearchResultListItem },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('vsl/containerReleaseOrder', ['containerReleaseOrderList']),
  },
  created() {},
  destroyed() {},
  methods: {
    ...mapMutations('vsl/containerReleaseOrder', ['RESET']),
    ...mapActions('vsl/containerReleaseOrder', ['searchContainerReleaseOrder']),
  },
};
</script>

<style></style>
