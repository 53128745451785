var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          attrs: { placeholder: _vm.placeholder },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _vm.required
                    ? _c("span", { staticClass: "red--text" }, [
                        _c("strong", [_vm._v("* ")]),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "v-text-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }